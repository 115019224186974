import makeFAIcon from "utils/makeFAIcon";

import {
  faShoppingCart,
  faLock,
  faCode,
  faMobile,
  faLaptopCode,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const MobilePhoneIcon = makeFAIcon(faMobile);
export const LockIcon = makeFAIcon(faLock);
export const CodeIcon = makeFAIcon(faCode);
export const LaptopCodeIcon = makeFAIcon(faLaptopCode);
export const PencilRuleIcon = makeFAIcon(faPencilRuler);
